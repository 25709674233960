import { axiosInstance } from "../../axios";
const GETSearch = `/api/BlacklistVehicle/Search`;
const GETGetByKey = `/api/BlacklistVehicle/GetByKey`;
const GETComments = `/api/BlacklistVehicle/SearchCommentList`;
const POSTCreatePostcode = `/api/BlacklistVehicle/Create`;
const POSTRevoke = `/api/BlacklistVehicle/Revoke`;

const search = async function(search) {
  try {
    const response = await axiosInstance.bhAPIInstance.get(`${GETSearch}/${search}`);
    let data = parseList(response);
    return data;
  } catch (error) {
    return [];
  }
};

const getByKey = async function(id) {
  try {
    const response = await axiosInstance.bhAPIInstance.get(`${GETGetByKey}/${id}`);
    return response.data;
  } catch (error) {
    return [];
  }
};

const searchComments = async function(id) {
  try {
    const response = await axiosInstance.bhAPIInstance.get(`${GETComments}/${id}`);
    return response.data;
  } catch (error) {
    return [];
  }
};

const createBlacklist = async function(blacklist) {
  try {
    const response = await axiosInstance.bhAPIInstance.post(POSTCreatePostcode, blacklist);
    return response.data;
  } catch (error) {
    return null;
  }
};

const revokeBlacklist = async function(comments) {
  try {
    const response = await axiosInstance.bhAPIInstance.post(POSTRevoke, comments);
    return response.data;
  } catch (error) {
    return null;
  }
};

const parseList = response => {
  if (response.status !== 200) throw Error(response.message);
  if (!response.data) return [];
  let list = response.data;
  if (typeof list !== "object") {
    list = [];
  }
  return list;
};

export const blacklistData = {
  search,
  getByKey,
  createBlacklist,
  revokeBlacklist,
  searchComments
};
